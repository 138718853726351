import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import saga from "./saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "./storage";
import authSlice from "./Slices/AuthSlice";
import alertSlice from "./Slices/AlertSlice";
import locationSlice from "./Slices/LocationSlice";
import claimSlice from "./Slices/ClaimSlice";
import modalSlice from "./Slices/ModalSlice";
import reviewSlice from "./Slices/ReviewSlice";
import emailSlice from "./Slices/EmailSlice";

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const rootReducer = combineReducers({
  authReducer: authSlice.reducer,
  alertReducer: alertSlice.reducer,
  locationReducer: locationSlice.reducer,
  claimReducer: claimSlice.reducer,
  modalReducer: modalSlice.reducer,
  reviewReducer: reviewSlice.reducer,
  emailReducer: emailSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

const persistor = persistStore(store);
sagaMiddleware.run(saga);
export { store, persistor };