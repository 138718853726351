import { all, call, takeEvery, put } from "redux-saga/effects";
import { reviewAction } from "./index";
import api from "../../../utils/api";
export function* getReviewSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      `/single-website-review/${payload}`
    );
    yield put(reviewAction.getReviewSuccess(data));
  } catch (e) {
    yield put(reviewAction.getReviewFailue());
  }
}
export function* updateReviewSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/ask-to-revise/update/${payload?.id}`,
      payload?.data
    );
    yield put(reviewAction.updateReviewSuccess());
    if (typeof payload?.onAddSuccess === "function") {
      payload?.onAddSuccess();
    }
  } catch (e) {
    yield put(reviewAction.updateReviewFailue());
  }
}
export function* getInviteSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      `/website-invite-show/${payload}`
    );
    yield put(reviewAction.getInviteSuccess(data));
  } catch (e) {
    yield put(reviewAction.getInviteFailue());
  }
}
export function* addReviewSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/add-review/${payload?.id}`,
      payload?.data
    );
    yield put(reviewAction.addReviewSuccess());
    if (typeof payload?.onAddSuccess === "function") {
      payload?.onAddSuccess();
    }
  } catch (e) {
    yield put(reviewAction.addReviewFailue());
  }
}

export function* getAttributesSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      `/review-setting?all=true`
    );
    yield put(reviewAction.getAttributesSuccess(data));
  } catch (e) {
    yield put(reviewAction.getAttributesFailue());
  }
}

export function* getLocationSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      `/cqc-location/${payload}`
    );
    yield put(reviewAction.getLocationSuccess(data));
  } catch (e) {
    yield put(reviewAction.getLocationFailue());
  }
}

export function* getReviewFlow() {
  yield takeEvery(reviewAction.getReview, getReviewSaga);
}
export function* updateReviewFlow() {
  yield takeEvery(reviewAction.updateReview, updateReviewSaga);
}
export function* getInviteFlow() {
  yield takeEvery(reviewAction.getInvite, getInviteSaga);
}
export function* addReviewFlow() {
  yield takeEvery(reviewAction.addReview, addReviewSaga);
}
export function* getAttributesFlow() {
  yield takeEvery(reviewAction.getAttributes, getAttributesSaga);
}
export function* getLocationFlow() {
  yield takeEvery(reviewAction.getLocation, getLocationSaga);
}

function* ReviewSaga() {
  yield all([
    getReviewFlow(),
    updateReviewFlow(),
    getInviteFlow(),
    addReviewFlow(),
    getAttributesFlow(),
    getLocationFlow(),
  ]);
}

export default ReviewSaga;
