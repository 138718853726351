import { createSlice } from "@reduxjs/toolkit";

const claimSlice = createSlice({
  name: "claimReducer",
  initialState: {
    isFetchingProviders: false,
    providers: [],
    isSubmiting: false,
    isSubmittingEnquiry: false,
    isVerifyingCode: false,
    isSubmittingReview: false,
  },
  reducers: {
    searchProvider: (state, action) => {
      state.isFetchingProviders = true;
      state.providers = [];
    },
    searchProviderSuccess: (state, action) => {
      state.isFetchingProviders = false;
      state.providers = action.payload;
    },
    searchProviderFailure: (state) => {
      state.isFetchingProviders = false;
      state.providers = [];
    },
    submitClaim: (state, action) => {
      state.isSubmiting = true;
    },
    submitClaimSuccess: (state, action) => {
      state.isSubmiting = false;
    },
    submitClaimFailure: (state, action) => {
      state.isSubmiting = false;
    },
    requestCode: (state, action) => {
      state.isRequesting = true;
    },
    requestCodeSuccess: (state, action) => {
      state.isRequesting = false;
    },
    requestCodeFailure: (state, action) => {
      state.isRequesting = false;
    },
    submitEnquiry: (state, action) => {
      state.isSubmittingEnquiry = true;
    },
    submitEnquirySuccess: (state, action) => {
      state.isSubmittingEnquiry = false;
    },
    submitEnquiryFailure: (state, action) => {
      state.isSubmittingEnquiry = false;
    },
    verifyCode: (state, action) => {
      state.isVerifyingCode = true;
    },
    verifyCodeSuccess: (state, action) => {
      state.isVerifyingCode = false;
    },
    verifyCodeFailure: (state, action) => {
      state.isVerifyingCode = false;
    },
    submitReview: (state, action) => {
      state.isSubmittingReview = true;
    },
    submitReviewSuccess: (state, action) => {
      state.isSubmittingReview = false;
    },
    submitReviewFailure: (state, action) => {
      state.isSubmittingReview = false;
    },
  },
});

export default claimSlice;

export const claimAction = claimSlice.actions;