import { createSlice } from "@reduxjs/toolkit";

const emailSlice = createSlice({
  name: "emailReducer",
  initialState: {
    isSubmiting: false,
  },
  reducers: {
    unsubscribe: (state, action) => {
      state.isSubmiting = true;
    },
    unsubscribeSuccess: (state, action) => {
      state.isSubmiting = false;
    },
    unsubscribeFailure: (state, action) => {
      state.isSubmiting = false;
    },
    subscribe: (state, action) => {
      state.isSubmiting = true;
    },
    subscribeSuccess: (state, action) => {
      state.isSubmiting = false;
    },
    subscribeFailure: (state, action) => {
      state.isSubmiting = false;
    },
  },
});

export default emailSlice;

export const emailAction = emailSlice.actions;