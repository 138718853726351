import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modalReducer",
  initialState: {
    isBrochureModalOpen: false,
    isRoomModalOpen: false,
    isVideoModalOpen: false,
    isReviewModalOpen: false,
  },
  reducers: {
    toggleBroucherModal: (state, action) => {
      state.isBrochureModalOpen = action.payload;
    },
    toggleRoomModal: (state, action) => {
      state.isRoomModalOpen = action.payload;
    },
    toggleVideoModal: (state, action) => {
      state.isVideoModalOpen = action.payload;
    },
    toggleReviewModal: (state, action) => {
      state.isReviewModalOpen = action.payload;
    },
  },
});

export default modalSlice;

export const modalAction = modalSlice.actions;
