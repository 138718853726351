import { all } from "redux-saga/effects";
import AuthSaga from "./Slices/AuthSlice/AuthSaga";
import LocationSaga from "./Slices/LocationSlice/LocationSaga";
import ClaimSaga from "./Slices/ClaimSlice/ClaimSaga";
import ReviewSaga from "./Slices/ReviewSlice/ReviewSaga";
import EmailSaga from "./Slices/EmailSlice/EmailSaga";

function* saga() {
  yield all([
    AuthSaga(),
    LocationSaga(),
    ClaimSaga(),
    ReviewSaga(),
    EmailSaga(),
  ]);
}

export default saga;
