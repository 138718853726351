import { all, call, takeEvery, put } from "redux-saga/effects";
import { locationAction } from "./index";
import api from "../../../utils/api";

export function* getFilterDataSaga() {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      `/type-of-care-list?all=true`
    );
    if (data?.length > 0) {
      yield put(locationAction.getFilterDataSuccess(data));
    }
  } catch (e) {
    yield put(locationAction.getFilterDataFailure());
  }
}

export function* getFilterDataFlow() {
  yield takeEvery(locationAction.getFilterData, getFilterDataSaga);
}

function* LocationSaga() {
  yield all([getFilterDataFlow()]);
}

export default LocationSaga;
