import { all, call, takeEvery, put } from "redux-saga/effects";
import { emailAction } from "./index";
import api from "../../../utils/api";
import QueryString from "query-string";
import { alertAction } from "../AlertSlice";

export function* unsubscribeSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/unsubscribe-mail`,
      payload
    );
    if (data) {
      yield put(emailAction.unsubscribeSuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess("success");
      }
    }
  } catch (e) {
    if (typeof payload?.onSuccess === "function") {
      payload?.onSuccess("failure");
    }
    yield put(emailAction.unsubscribeFailure("failure"));
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* subscribeSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/subscribe-mail`,
      payload
    );
    if (data) {
      yield put(emailAction.subscribeSuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess("success");
      }
    }
  } catch (e) {
    if (typeof payload?.onSuccess === "function") {
      payload?.onSuccess("failure");
    }
    yield put(emailAction.subscribeFailure("failure"));
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* unsubscribeFlow() {
  yield takeEvery(emailAction.unsubscribe, unsubscribeSaga);
}
export function* subscribeFlow() {
  yield takeEvery(emailAction.subscribe, subscribeSaga);
}

function* EmailSaga() {
  yield all([unsubscribeFlow(), subscribeFlow()]);
}

export default EmailSaga;
