import { createSlice } from "@reduxjs/toolkit";

const reviewSlice = createSlice({
  name: "reviewReducer",
  initialState: {
    review: {},
    isReviewFetched: false,
    isReviewEditable: false,
    isFetching: false,
    isUpdating: false,
    isInviteFetched: false,
    isInviteEditable: false,
    invite: {},
    isAddingReview: false,
    attributes: [],
    isFetchingAttributes: false,
    isFetchingLocation: false,
    location: {},
  },
  reducers: {
    getReview: (state) => {
      state.isFetching = true;
      state.review = {};
      state.isReviewFetched = false;
      state.isReviewEditable = false;
    },
    getReviewSuccess: (state, action) => {
      state.isFetching = false;
      state.review = action.payload;
      state.isReviewFetched = true;
      state.isReviewEditable = action?.payload?.ask_to_revise ?? false;
    },
    getReviewFailue: (state) => {
      state.isFetching = false;
      state.review = {};
      state.isReviewFetched = true;
      state.isReviewEditable = false;
    },
    updateReview: (state) => {
      state.isUpdating = true;
    },
    updateReviewSuccess: (state) => {
      state.isUpdating = false;
    },
    updateReviewFailue: (state) => {
      state.isUpdating = false;
    },
    getInvite: (state) => {
      state.isFetching = true;
      state.invite = {};
      state.isInviteFetched = false;
      state.isInviteEditable = false;
    },
    getInviteSuccess: (state, action) => {
      state.isFetching = false;
      state.invite = action.payload;
      state.isInviteFetched = true;
      state.isInviteEditable =
        action?.payload?.status === "Invited" ? true : false;
    },
    getInviteFailue: (state) => {
      state.isFetching = false;
      state.invite = {};
      state.isInviteFetched = true;
      state.isInviteEditable = false;
    },
    addReview: (state) => {
      state.isAddingReview = true;
    },
    addReviewSuccess: (state) => {
      state.isAddingReview = false;
    },
    addReviewFailue: (state) => {
      state.isAddingReview = false;
    },
    getAttributes: (state) => {
      state.isFetchingAttributes = true;
      state.attributes = [];
    },
    getAttributesSuccess: (state, action) => {
      state.isFetchingAttributes = false;
      state.attributes = action.payload;
    },
    getAttributesFailue: (state) => {
      state.isFetchingAttributes = false;
      state.attributes = [];
    },
    getLocation: (state) => {
      state.isFetchingLocation = true;
      state.location = {};
    },
    getLocationSuccess: (state, action) => {
      state.isFetchingLocation = false;
      state.location = action.payload;
    },
    getLocationFailue: (state) => {
      state.isFetchingLocation = false;
      state.location = {};
    },
  },
});

export default reviewSlice;

export const reviewAction = reviewSlice.actions;
