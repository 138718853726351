import { all, call, takeEvery, put } from "redux-saga/effects";
import { claimAction } from "./index";
import api from "../../../utils/api";
import QueryString from "query-string";
import { alertAction } from "../AlertSlice";

export function* searchProviderSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      `/company-search?${QueryString.stringify(payload)}`
    );
    if (data?.data) {
      yield put(claimAction.searchProviderSuccess(data?.data));
    }
  } catch (e) {
    yield put(claimAction.searchProviderFailure());
  }
}
export function* submitClaimSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/company-signup/company-name`,
      payload?.data
    );
    if (data) {
      yield put(claimAction.submitClaimSuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess();
      }
    }
  } catch (e) {
    yield put(claimAction.submitClaimFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* requestCodeSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/company-signup/company-name`,
      payload?.data
    );
    if (data) {
      yield put(claimAction.requestCodeSuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess();
      }
    }
  } catch (e) {
    yield put(claimAction.requestCodeFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* submitEnquirySaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/signup-enquiry`,
      payload?.data
    );
    if (data) {
      yield put(claimAction.submitEnquirySuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess();
      }
    }
  } catch (e) {
    yield put(claimAction.submitEnquiryFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* verifyCodeSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/verify-code`,
      payload?.data
    );
    if (data) {
      yield put(claimAction.verifyCodeSuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess(data);
      }
    }
  } catch (e) {
    yield put(claimAction.verifyCodeFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* submitReviewSaga({ payload }) {
  try {
    const { data } = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `/review-form`,
      payload?.data
    );
    if (data) {
      yield put(claimAction.submitReviewSuccess(data));
      if (typeof payload?.onSuccess === "function") {
        payload?.onSuccess();
      }
    }
  } catch (e) {
    yield put(claimAction.submitReviewFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}

export function* searchProviderFlow() {
  yield takeEvery(claimAction.searchProvider, searchProviderSaga);
}
export function* submitClaimFlow() {
  yield takeEvery(claimAction.submitClaim, submitClaimSaga);
}
export function* requestCodeFlow() {
  yield takeEvery(claimAction.requestCode, requestCodeSaga);
}
export function* submitEnquiryFlow() {
  yield takeEvery(claimAction.submitEnquiry, submitEnquirySaga);
}
export function* verifyCodeFlow() {
  yield takeEvery(claimAction.verifyCode, verifyCodeSaga);
}
export function* submitReviewFlow() {
  yield takeEvery(claimAction.submitReview, submitReviewSaga);
}

function* ClaimSaga() {
  yield all([
    searchProviderFlow(),
    submitClaimFlow(),
    requestCodeFlow(),
    submitEnquiryFlow(),
    verifyCodeFlow(),
    submitReviewFlow(),
  ]);
}

export default ClaimSaga;
