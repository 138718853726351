import { Provider } from "react-redux";

import dynamic from "next/dynamic";
import { ToastContainer } from "react-toastify";
import NextNProgress from "nextjs-progressbar";

import "../styles/global.scss";
import "../styles/pagination.scss";

import { store } from "../stores/store";
import Script from "next/script";

const Layout = dynamic(() => import("../components/Layout"), {
  loading: () => <></>,
});
const Alerts = dynamic(() => import("../components/Alerts"), {
  loading: () => <></>,
});
const PageProgressBar = dynamic(() => import("../components/PageProgressBar"), {
  ssr: true,
});

function App({ Component, pageProps }) {
  return (
    <>
      <Script
        strategy="beforeInteractive"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API}&libraries=places`}
      />
      <NextNProgress color="#1d2837" />
      <ToastContainer />
      <Provider store={store}>
        <Alerts />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </>
  );
}

export default App;
