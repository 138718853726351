import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authReducer",
  initialState: {
    userInfo: {},
    isSubmittingEnquiry: false,
    submitEnquirySuccess: false,
    // Verify Staff
    isValidatingStaff: false,
    validStaff: false,
    // Submit Staff Verification
    isActivatingStaff: false,
    //
    isFetchingUser: false,
    user: {},
  },
  reducers: {
    checkAuth: (state) => {
      state.userInfo = "moon";
    },
    submitEnquiry: (state) => {
      state.isSubmittingEnquiry = true;
      state.submitEnquirySuccess = false;
    },
    submitEnquirySuccess: (state) => {
      state.isSubmittingEnquiry = false;
      state.submitEnquirySuccess = true;
    },
    submitEnquiryFailure: (state) => {
      state.isSubmittingEnquiry = false;
      state.isSubmittingEnquiry = false;
    },
    // Submit Staff Verification
    activateStaff: (state) => {
      state.isActivatingStaff = true;
    },
    activateStaffSuccess: (state) => {
      state.isActivatingStaff = false;
    },
    activateStaffFailure: (state) => {
      state.isActivatingStaff = false;
    },
    // Get User Details
    getUser: (state) => {
      state.isFetchingUser = true;
      state.user = {};
    },
    getUserSuccess: (state, action) => {
      state.isFetchingUser = false;
      state.user = action.payload;
    },
    getUserFailure: (state) => {
      state.isFetchingUser = true;
      state.user = {};
    },
    resetUser: (state) => {
      state.isFetchingUser = false;
      state.user = {};
    },
  },
});

export default authSlice;

export const authAction = authSlice.actions;
