import { all, call, put, takeEvery } from "redux-saga/effects";
import api from "../../../utils/api";
import { alertAction } from "../AlertSlice";
import { authAction } from "./index";

export function* checkAuthSaga() {
  try {
  } catch (error) {}
}

export function* submitEnquirySaga({ payload, onSubmitSuccess }) {
  try {
    const data = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `add-enquiry/${process.env.NEXT_PUBLIC_COMPANY_KEY}`,
      payload
    );
    if (data) {
      yield put(authAction.submitEnquirySuccess());
      yield put(alertAction.successAlert("Enquiry submitted successfully!"));
      typeof onSubmitSuccess === "function" && onSubmitSuccess();
    }
  } catch (e) {
    yield put(authAction.submitEnquiryFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* activateStaffSaga({ payload }) {
  try {
    const data = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).post,
      `staff-activate`,
      payload?.data
    );
    if (data) {
      yield put(authAction.activateStaffSuccess());
      typeof payload?.onSuccess === "function" && payload?.onSuccess();
    }
  } catch (e) {
    yield put(authAction.activateStaffFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}
export function* getUserSaga({ payload }) {
  try {
    const data = yield call(
      api(process.env.NEXT_PUBLIC_TOKEN).get,
      payload.type === "admin"
        ? `single-admin-show/${payload.id}`
        : `single-staff-show/${payload.id}`
    );
    if (data) {
      yield put(authAction.getUserSuccess(data?.data));
    }
  } catch (e) {
    yield put(authAction.getUserFailure());
    if (typeof e === "string") yield put(alertAction.errorAlert(e));
    else if (typeof e.error === "string")
      yield put(alertAction.errorAlert(e.error));
    else yield put(alertAction.errorAlert("Something went wrong!"));
  }
}

export function* checkAuthFlow() {
  yield takeEvery(authAction.checkAuth, checkAuthSaga);
}
export function* submitEnquiryFlow() {
  yield takeEvery(authAction.submitEnquiry, submitEnquirySaga);
}
export function* activateStaffFlow() {
  yield takeEvery(authAction.activateStaff, activateStaffSaga);
}
export function* getUserFlow() {
  yield takeEvery(authAction.getUser, getUserSaga);
}

function* AuthSaga() {
  yield all([
    checkAuthFlow(),
    submitEnquiryFlow(),
    activateStaffFlow(),
    getUserFlow(),
  ]);
}

export default AuthSaga;
