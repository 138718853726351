import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alertReducer",
  initialState: {
    successSnackBarOpen: false,
    errorSnackBarOpen: false,
    errorSnackBarOpen: '',
    errorSnackBarMessage: '',
  },
  reducers: {
    successAlert: (state, action) => {
      state.successSnackBarOpen = true;
      state.successSnackBarMessage = action.payload;
    },
    errorAlert: (state, action) => {
      state.errorSnackBarOpen = true;
      state.errorSnackBarMessage = action.payload;
    },
    clearAlert: (state, action) => {
      state.errorSnackBarOpen = false;
      state.successSnackBarOpen = false;
    },
  },
});

export default alertSlice;

export const alertAction = alertSlice.actions;