import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "locationReducer",
  initialState: {
    filters: {
      fetched: true,
      keys: {
        search_term: "",
        city: "",
        address_1: "",
        address_2: "",
        min: "",
        max: "",
        page: "1",
        care_category: "",
        residency_type: "",
        service_type: "",
        care_type: "",
        distance: "",
        room_size: "",
        title: "",
      },
    },
    sorting: {},
    filterModalOpen: false,
    isFetchingFilters: false,
    filterData: [],
    form: "location",
    reviewList: {},
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    toggleFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    getFilterData: (state, action) => {
      state.isFetchingFilters = true;
      state.filterData = [];
    },
    getFilterDataSuccess: (state, action) => {
      state.isFetchingFilters = false;
      state.filterData = action.payload;
    },
    getFilterDataFailure: (state, action) => {
      state.isFetchingFilters = false;
      state.filterData = [];
    },
    toggleForm: (state, action) => {
      state.form = action.payload;
    },
    populateReviews: (state, action) => {
      state.reviewList = action.payload;
    },
  },
});

export default locationSlice;

export const locationAction = locationSlice.actions;
